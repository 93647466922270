import { Injectable, inject } from '@angular/core';
import { BaseFacadeService } from './base.facade.service';
import { AgentTypeEffect } from '../@ngrx/agent-type/agent-type.effect';
import { AgentTypeStore } from '../@ngrx/agent-type/agent-type.reducer';
import { IAgentType, ISaveAgentType } from '../@ngrx/agent-type/agent-type.model';
import { map } from 'rxjs';
import { EVFunctions } from 'ets-fe-ng-sdk';
import { AgentService } from '../../../../admin/src/app/dashboard/intermediary-desk/extras/agent.service';
import {
  AgentTypeService,
  IAgentTypeSQ,
} from '../../../../admin/src/app/dashboard/set-ups/agent-type/agent-type.service';

@Injectable({
  providedIn: 'root',
})
export class AgentTypeFacadeService extends BaseFacadeService {
  protected effect = inject(AgentTypeEffect);
  constructor(public agentS: AgentTypeService) {
    super();
    this.fetchAll();
  }

  fetchAll(companyCode?: string) {
    this.store.dispatch(AgentTypeStore.actions.API.fetchAll({ company: companyCode }));
  }

  fetchSingle(query: IAgentTypeSQ) {
    return this.agentS.fetchSingle(query);
  }

  fetchDescriptionByCode = (itemCode: string) => {
    return this.select(
      this.store.select(AgentTypeStore.selectors.selectByCode(itemCode)),
      AgentTypeStore.actions.API.fetchAll({}),
    ).pipe(map((r) => EVFunctions.strConcatenator2(itemCode, r?.description!)));
  };

  selectAll=()=> {
    return this.select(
      this.store.select(AgentTypeStore.selectors.selectAll),
      AgentTypeStore.actions.API.fetchAll({}),
    );
  }

  selectByAgentType(agentType: string) {
    return this.select(
      this.store.select(AgentTypeStore.selectors.selectAll),
      AgentTypeStore.actions.API.fetchAll({}),
    );
  }

  selectAllForInput = (companyCode?: string) => {
    return this.select(
      this.store.select(AgentTypeStore.selectors.selectAllForInput),
      AgentTypeStore.actions.API.fetchAll({ company: companyCode }),
    );
  };

  save(item: ISaveAgentType) {
    return !item.id
      ? this.ngrxPromise(AgentTypeStore.actions.API.create({ item }), {
          success: AgentTypeStore.actions.API.createSuccess,
          error: AgentTypeStore.actions.API.createError,
        })
      : this.ngrxPromise(AgentTypeStore.actions.API.update({ item }), {
          success: AgentTypeStore.actions.API.updateSuccess,
          error: AgentTypeStore.actions.API.updateError,
        });
  }
}
