import { Injectable } from '@angular/core';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import {
  IAgentType,
  ISaveAgentType,
} from '../../../../../../shared/src/lib/@ngrx/agent-type/agent-type.model';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { environment } from '../../../../../../shared/src/environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentTypeService extends BaseService {
  constructor() {
    super();
    this.baseURL = `v1/setup/agent-type/`;
  }

  fetchAll(query?: IAgentTypeSQ) {
    if (query && environment.userCompanyCode) query.company = environment.userCompanyCode;
    return this.get<ISR<IAgentType>>(`search`, query);
  }

  fetchSingle(query?: IAgentTypeSQ) {
    return this.fetchAll(query).pipe(map((r) => r.content?.[0]));
  }

  create(data: ISaveAgentType) {
    return this.post<IAgentType>(data);
  }

  update(data: ISaveAgentType) {
    return this.put<IAgentType>(data, data.id?.toString());
  }
}

export interface IAgentTypeSQ {
  agentType?: string;
  company?: string;
}
