import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IAgentType, ISaveAgentType } from './agent-type.model';
import { ICodeTitle } from 'ets-fe-ng-sdk';
export namespace AgentTypeStore {
  //#region STORE
  export interface IState extends EntityState<IAgentType> {
    fetchedAll?: boolean;
  }

  const adapter: EntityAdapter<IAgentType> = createEntityAdapter<IAgentType>({
    sortComparer: (a, b) => a.description.localeCompare(b.description),
    selectId: (i) => i.agentType,
  });

  const initialState: IState = adapter.getInitialState();
  //#endregion

  //#region ACTIONS
  export const actions = {
    API: createActionGroup({
      source: 'Agent Type API',
      events: {
        create: props<{ item: ISaveAgentType }>(),
        createError: props<{ error: any }>(),
        createSuccess: props<{ result: IAgentType }>(),
        fetchAll: props<{ company?: string }>(),
        fetchAllReset: emptyProps(),
        fetchAllError: props<{ error: any }>(),
        fetchAllSuccess: props<{ result: IAgentType[] }>(),
        fetchSingle: props<{ agentType: string }>(),
        fetchSingleError: props<{ error: any }>(),
        fetchSingleSuccess: props<{ result: IAgentType }>(),
        update: props<{ item: ISaveAgentType }>(),
        updateError: props<{ error: any }>(),
        updateSuccess: props<{ result: IAgentType }>(),
      },
    }),
  };
  //#endregion

  //#region SELECTORS
  export namespace selectors {
    const select = (state: IAppState) => state.agentType;
    const selectAuth = (state: IAppState) => state.auth;
    const selectMap = createSelector(select, adapter.getSelectors().selectEntities);
    const _selectAll = createSelector(select, adapter.getSelectors().selectAll);
    const _userCompany = createSelector(selectAuth, (sa) => sa.userCompany);
    
    export const selectAll = createSelector(_selectAll, _userCompany, (allAgentTypes, userCompany) =>
      userCompany ? allAgentTypes?.filter((x) => x.company == userCompany?.companyCode) : allAgentTypes,
    );
    export const selectByAgentType = (agentType: string) =>
      createSelector(selectMap, (map) => map[agentType]);

    export const selectByCode = (code: string) => createSelector(selectMap, (map) => map[code]);

    export const selectAllForInput = createSelector(
      selectAll,
      (list) => list.map<ICodeTitle>((x) => ({ code: x.agentType, title: x.description })) || [],
    );
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.API.fetchAllSuccess, (state, { result: items }) => adapter.setAll(items, state)),
    on(actions.API.fetchAllSuccess, (state, { result: items }) => ({ ...state, fetchedAll: true })),
    on(actions.API.fetchSingleSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
    on(actions.API.updateSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
    on(actions.API.createSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
  );
  //#endregion
}
