import { Injectable, inject } from '@angular/core';
import { catchError, exhaustMap, filter, first, map, mergeMap, of, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { AgentTypeStore } from './agent-type.reducer';
import { BaseEffect } from '../base.effect';
import { AgentTypeService } from '../../../../../admin/src/app/dashboard/set-ups/agent-type/agent-type.service';

@Injectable()
export class AgentTypeEffect extends BaseEffect {
  protected service = inject(AgentTypeService);

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentTypeStore.actions.API.fetchAll),
      mergeMap((action) =>
        this.store.select(AgentTypeStore.selectors.selectAll).pipe(
          first(),map((r) => ({ existing: r, action }))),
      ),
      filter((action) => !action.existing.length),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => AgentTypeStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(AgentTypeStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchAllReset = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentTypeStore.actions.API.fetchAllReset),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => AgentTypeStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(AgentTypeStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentTypeStore.actions.API.fetchSingle),
      exhaustMap((action) =>
        this.service.fetchAll({ agentType: action.agentType }).pipe(
          map((r) => AgentTypeStore.actions.API.fetchSingleSuccess({ result: r.content?.[0] })),
          catchError((error) => of(AgentTypeStore.actions.API.fetchSingleError({ error }))),
        ),
      ),
    ),
  );

  createSuccess = this.actions$.pipe(
    ofType(AgentTypeStore.actions.API.createSuccess),
    tap(() => this.store.dispatch(AgentTypeStore.actions.API.fetchAllReset())),
  );
  createError = this.actions$.pipe(ofType(AgentTypeStore.actions.API.createError));
  updateSuccess = this.actions$.pipe(
    ofType(AgentTypeStore.actions.API.updateSuccess),
    tap(() => this.store.dispatch(AgentTypeStore.actions.API.fetchAllReset())),
  );
  updateError = this.actions$.pipe(ofType(AgentTypeStore.actions.API.updateError));

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentTypeStore.actions.API.create),
      exhaustMap((action) =>
        this.service.create(action.item).pipe(
          map((r) =>
            AgentTypeStore.actions.API.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(AgentTypeStore.actions.API.createError({ error }))),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentTypeStore.actions.API.update),
      exhaustMap((action) =>
        this.service.update(action.item).pipe(
          map((r) =>
            AgentTypeStore.actions.API.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(AgentTypeStore.actions.API.updateError({ error }))),
        ),
      ),
    ),
  );
 
}
